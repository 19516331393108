const Header = () => {

    // Generate social links from https://sharingbuttons.io/

    return (
        <header>
  <div className="row mx-0 align-items-center hdr1">
    <div className="col-12 col-lg-auto clma text-center text-lg-start mb-3 mb-lg-0">
      <div className="hvr1 d-inline-block itxt11">
        <img src="./Images/Logo.png" alt="HawFar"/>
      </div>
    </div>
    <div className="col clmb text-center mb-3 mb-lg-0">
      <div className="inrt sclshr24">
        <a href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fhawfar.com" target="_blank" rel="noreferrer" aria-label="Share on Facebook" className="hvr1 d-inline-block">
          <img alt="" style={{ marginBottom: "-8px" }} src="./Images/fb_share.png" />
        </a>
        <a href="#" className="hvr1 d-inline-block">
          <img
          alt=""
            style={{ marginBottom: "-8px" }}
            src="./Images/instagram_share.png"
          />
        </a>
        <a href="https://twitter.com/intent/tweet/?text=Hawfar%20chat%20https%3A%2F%2Fhawfar.com%2C%20a%20great%20way%20to%20meet%20new%20people!&amp;url=https%3A%2F%2Fhawfar.com" target="_blank" rel="noreferrer" aria-label="Share on Twitter" className="hvr1 d-inline-block">
          <img
          alt=""
            style={{ marginBottom: "-8px" }}
            src="./Images/tweet_share.png"
          />
        </a>
        <a href="whatsapp://send?text=Hawfar%20chat%20https%3A%2F%2Fhawfar.com%2C%20a%20great%20way%20to%20meet%20new%20people!%20https%3A%2F%2Fhawfar.com" data-action="share/whatsapp/share"  
        target="_blank" rel="noreferrer" className="hvr1 d-inline-block">
          <img
          alt=""
            style={{ marginBottom: "-8px" }}
            src="./Images/whatsapp_share.png"
          />
        </a>
      </div>
    </div>
    <div className="col-12 col-lg-auto clmc text-center">
      <div className="inrt onlsct24">
        <img className="hvr2" src="./Images/ppl_online_icn.png" alt="Online People" />
        <span>
          <strong>1000+</strong>
        </span>
        <span>online now</span>
      </div>
    </div>
  </div>
</header>

    )
}

export default Header;