import React, {useState, useEffect, useCallback } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainContext from './context';
import Header from './Components/Header/header';
import Homepage from './Pages/Hp/Homepage';
import ModalComp from './Components/Modal/modal';

const MyProvider = ({children}) => {

  const [userData, setUserData] = useState(null); // Used for user data
  const [general, setgeneral] = useState(false);


  // Set the app data options, @todo get those options from the server
  const [AppDataOptions, setAppDataOptions] = useState({
    lang: "eng",
    trig: 1 // We can change this number to refetch the app data, best case is that if our website is loaded and our app failed to load the app data then we can change this number so the app will re try to fetch the data from server
  });

  // Set the required data to our shared function class
  //SharedFunctions.SetAppData(AppDataOptions);


  const value = {
   userData,
   setUserData,
   general,
   setgeneral,
   AppDataOptions,
   setAppDataOptions
  };


  // Set the data to our object so other files can access it, currently not using it
  //AppCustomContext.SetMainAppContext(value);


  return <MainContext.Provider value={value}>{children}</MainContext.Provider>

}


function App() {

  console.log("App function called");

  return (
    <div className="App">
      <MyProvider>

      <Header />
          <Routes>
            <Route path="/" >
              <Route index element={<Homepage />} />
            </Route>
          </Routes>
      </MyProvider>


      <ModalComp />

    </div>
  );
}

export default App;
