import React, {useState, useEffect, useRef } from 'react'
import ModalFns from './modal-fn';


/**
 * 
 * @typedef {import('./modal-fn').modalDataDoc} modalDatadoc
 */

/* This modal will be keep as one instance but will act as the paging, means if we add new data to the modal, then it's data will 
save on the array and the modal will show the content of the last array item and if the modal is closed then the that array data will
be removed and the modal will show the previous array item data if available!
*/
// it's helper fn file to manipulate this modal


const ModalComp = () => {

    const [  modalDataArr, setmodalDataArr ] = useState([]);
    const stateRef = useRef();
    let DomElemRef = useRef(null); // Create the reference

    // As we need to access the state data on the javascript dom event, so for that we have two ways, 1 pass the data through function or we use the useref which means we don't have to pass this variable to the function, the function will get the uptodate value by using useref
    stateRef.current = modalDataArr;


    // ----------- Function to get the last modal data which will be our current ----------
    /**
     * 
     * @returns {modalDatadoc | undefined}
     */
    const GetCurrentModalData = () => {
      let ImodalDatArr = stateRef.current;
      return ImodalDatArr.length > 0 ? ImodalDatArr[ImodalDatArr.length - 1] : undefined;
    }
    // ------------------------------------------------------------------------------------


    // ------------ Function to add the modal data on the array -------------

    /**
     * Function to add the modal data
     * @param {modalDatadoc} imodalData 
     */
    const AddModalDataFn = (imodalData) => {

      const iimodalData = ModalFns._GetModalOptns(imodalData);


      setmodalDataArr((prevData) => {

        return [...prevData, iimodalData];

      });


    }

    // ----------------------------------------------------------------------



    // ----------- Function to get the modal dom element ----------
    /**
     * 
     * @returns {modalDatadoc | undefined}
     */
     const GetModalDomElem = () => {

      // Ready the bootstrap modal elem
      return window.bootstrap.Modal.getOrCreateInstance(DomElemRef.current);

    }

    // ------------------------------------------------------------------------------------


    // Set the fn reference
    ModalFns._SetModalCntnFunc(AddModalDataFn);
    ModalFns._SetGetModalElemFn(GetModalDomElem);


    // ----------- On component mount/ modaldata changes then show/hide modal based on value -------------

    // On this modal mounted on the dom and on unmount. Add/remove event
    useEffect(() => {

      const onModalHidenFn = () => {

        let crnModal = GetCurrentModalData();

        // If undefined then set empty object
        crnModal = crnModal ? crnModal : {};

          // Call the oncloseCallback function if any
          if (typeof crnModal.onCloseCallBackFn === "function") {
            try {  crnModal.onCloseCallBackFn();  } catch (error) { /* */ }
          }
      
        

        // Remove the last data from the array as the modal is hidden for that element or if we the current modal have option to close all modal then close these
        setmodalDataArr((prevData) => {

          // If we want to close all modal then empty all data
          if (crnModal.CloseAllOnClose) { return [] }


          // We are here it means we want to only close last modal

          const lastIndx = prevData.length - 1;
          
          return prevData.filter((val, indexT) => {
            // Remove the last indexed item
            return indexT !== lastIndx
          });

        });

      }


      // Add the event of on modal hide on the element mounted
      DomElemRef.current.addEventListener('hidden.bs.modal', onModalHidenFn);


      // On modal unmount
      return () => {

        try {   DomElemRef.current.removeEventListener('hidden.bs.modal', onModalHidenFn);  } catch (error) { /* */ }
        
      }

    }, [DomElemRef]);

    

    useEffect(() => {
        // this runs when the component mounts or modal data change

        // Ready the bootstrap modal elem
        const modalElem = window.bootstrap.Modal.getOrCreateInstance(DomElemRef.current);

        // Get the current modal data
        const modalData = GetCurrentModalData();
       
        if (!modalData) {return;}

        /* Setting the backdrop, if we want to prevent the closing of modal on outside click then we have to provide "static"
          We are using the modal js element to edit data because if the modal already created then this setting can only be changed using js.
          Changing html on already created modal will not do anything that is why we are using the js updating
        */
        modalElem._config.backdrop = !modalData.closeAble ? "static" : true;
        modalElem._config.keyboard = !modalData.closeAble && false;


        if (modalData.showModal) {
            modalElem.show();
        } else {
          // Hide the modal which will trigger the event and which will remove the data data from our array to show prev modal content if any
            modalElem.hide();
        }
      
     
    
      }, [DomElemRef, modalDataArr]); // This use effect will only call if the element successfully mounted at first time or on every modalData changes


    // ----------------------------------------------------------------------------


    const modalId = "appModalT";

    // Get the current modal data
    /** @type {modalDatadoc} */
    let modalData = GetCurrentModalData();


    // If no modal data then get the default data just for default settings and dummy data
    if (!modalData) {modalData = ModalFns._GetModalOptns()}

    const lstIndx = modalDataArr.length - 1;
   
    return (
        <div
  ref={DomElemRef}
  key={modalId}
  className={`modal fade`}
  id={modalId}
  tabIndex={-1}
  aria-labelledby={modalId}
  aria-hidden="true"
  data-bs-backdrop={!modalData.closeAble ? "static" : "true"}
  data-bs-keyboard={!modalData.closeAble && "false"}
>
  <div className={`modal-dialog modal-dialog-centered ${modalData.LargeModal && "modal-xl"}`}>
    <div className="modal-content">
      <div className={`modal-header ${!modalData.title && "d-none"}`}>
        <h5 className={`modal-title text-center w-100`}>
          {modalData.title}
        </h5>

        {modalData.closeAble && <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />}
        
      </div>
      <div className="modal-body">
        {


          // Loop through the modal contents and render all, hide all except the last one.
          // We use this technique so the react don't have to rerender the previous modal content data if last one is closed
          modalDataArr.map((iModalDat, index) => {

            return (
             <div key={iModalDat.id} className={index !== lstIndx ? 'd-none' : ""}>
                {iModalDat.body}
             </div>
            )

          })
        }
      </div>
      
    </div>
  </div>
</div>

    )


}

export default ModalComp