import React, { useState, useEffect, useRef } from 'react';
import socketIO from 'socket.io-client';
import isNormalNat from '../../Other/NatTester';
import ModalFns from '../../Components/Modal/modal-fn';
import EmojiPicker from 'emoji-picker-react';
import Settings from "../../settings.json";

// @Note: If we are editing this then we have to add back these to the server match-maker file for validation
const interestArr = [
  "Politics", "Romance", "Friendship", "Sports", "Mature", "Young", "Christian", "Muslim", "Business", "Technology"
]


/**
 * Ready the json string  of the action object
 * @param {string} action 
 * @param {*} data 
 * @returns 
 */
const ReadyActionDatJson = function (action, data) {
  return JSON.stringify({
    action: action,
    data: data
  })
}

// Default chat section
const DefaultChatSctn = (props) => {

  const setstage = props.sharedObj.setstage;
  const Interests = props.sharedObj.Interests;
  const setInterests = props.sharedObj.setInterests;
  const canP2P = props.sharedObj.canP2P;
  let SetvidStream = props.sharedObj.setvidStream;
  const theme = props.sharedObj.theme;
  const settheme = props.sharedObj.settheme;

  // ---------- Video chat btn clicked ----------

  const vidChatBtnClck = () => {

    /*
    // Request user media permission
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then((stream) => {

      SetvidStream(stream);
    }

    ).catch((err) => {
      alert(err)
    })

    */

    // Change the stage to video chat
    setstage("vid_chat")

  }

  // --------------------------------------------


  // ----------- Switch theme ------------

  const themesArr = [{ key: "default", label: "Default" }, { key: "theme-1", label: "Theme 1" }]

  const switchTheme = (themeKey) => {

    const bodyDomElem = document.querySelector("body");

    // Loop through all themes and remove the theme class
    themesArr.forEach(element => {
      bodyDomElem.classList.remove(element.key)
    });

    // Add the supplied theme
    bodyDomElem.classList.add(themeKey);

    // Update the state
    settheme(themeKey)

  }

  // -------------------------------------

  return (
    <div className="d-inline-block w-100 p-3">

      <div className="iisct1">
        <div
          className="intrst_w text-center mx-auto"
          style={{ maxWidth: 260 }}
        >

          <p className="itxt3">What are your interests?</p>
          <select className="interests form-select mb-3"
            defaultValue={Interests}
            onChange={(e) => setInterests(e.target.value)}
          >
            <option value="def">Add your Interests (optional)</option>
            {
              interestArr.map((value, index) => {
                return (<option key={index} value={value.toLocaleLowerCase()}>{value}</option>)
              })
            }
          </select>
        </div>
        <div className="text-center mt-5 iisct2">
          <p className='iitxt1' style={{ color: "red", fontSize: 16, fontWeight: "bold" }}>
            Start Chat
          </p>
          <div onClick={() => setstage("txt_chat")} className="btnstl1 a hvr1">
            <div className="icnw">
              <img className="icn" src="./Images/msg_btn_icn.png" />
            </div>
            <div className="itxt_w">
              <p>Text</p>
            </div>
          </div>
          <div onClick={vidChatBtnClck} className="btnstl1 b hvr1">
            <div className="icnw" style={{ backgroundColor: "#00AEEF" }}>
              <img className="icn" src="./Images/video_btn_icn.png" />
            </div>
            <div className="itxt_w">
              <p>Video</p>
            </div>
          </div>
        </div>

      </div>

      <p className="itxt2">
        Hawfar is a great way to meet new people.
        <br />
        You are randomly connected to another person to talk one on one.
        <br />
        You can add your interests and you’ll be randomly connected with
        someone with the same interest.
        <br />
        <strong>You must be 18+ to use Hawfar</strong>, see our 'Terms of
        Service' and 'Community Guidelines' <br />
        Users are responsible for their behaviour while using Hawfar, please
        be nice : ){" "}
      </p>


      <div className='site_thm_w d-inline-block w-100 text-center mt-5'>

        <p className="iitxt1" style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>Site Theme</p>

        {
          themesArr.map((itheme) => {
            return (
              <button key={`itbn-${itheme.key}`} onClick={() => switchTheme(itheme.key)} className={`button btn ${itheme.key === theme ? "active btn-primary" : "btn-secondary"}`} style={{ marginRight: "7px" }}>{itheme.label}</button>
            )
          })
        }

      </div>

    </div>
  )

}


// ======================== Text Chat ==========================

// -------------------- Ready the Input TextArea Component ------------------

const InputReactComp = (props) => {
  const msgsFnsObj = props.msgsFnsObj;
  const sendMSg = props.sendMSg;
  //const msgT = props.msgT;
  //const setmsgT = props.setmsgT;
  const sendTypingStatus = props.sendTypingStatus;
  const [imsg, setimsg] = useState("");


  // Set the fn to our object
  msgsFnsObj.current.getMsg = () => imsg;
  msgsFnsObj.current.setMsg = setimsg;


  // On msgT value change then call the send typing status
  useEffect(() => {

    // Send the typing status
    sendTypingStatus(imsg);

  }, [imsg]);




  const onKeyDown = (e) => {

    /* Uncomment if want's to send the message on enter
      
      if(e.keyCode === 13 && e.shiftKey === false) {
          setimsg("");
          sendMSg();
      }

      */

  }


  const onChange = (event) => {

    const valT = event.target.value;

    // Set msg
    setimsg(valT);

  }


  return (
    <textarea
      id="msgtxtbox"
      className="w-100 actnmsgbox" rows="4" cols="50"
      onChange={onChange}
      value={imsg}
      onKeyDown={onKeyDown}
    >
    </textarea>
  )

}

// ------------------------------------------------------------------------

// ---------- Set stream to video by the element and the stream --------
const setVidToElem = (viddomElem, stream, isMute = false) => {

  if ('srcObject' in viddomElem) {
    viddomElem.srcObject = stream
  } else {
    viddomElem.src = window.URL.createObjectURL(stream) // for older browsers
  }

  viddomElem.muted = isMute;

  try { viddomElem.play(); } catch (error) { }

}
// --------------------------------------


// -------------- Function to get the visible browser height --------------

function GetVisibleWindHeight() {

  var vpHeight,
    tmp = document.createElement('div');

  tmp.id = "tmp_hght_elm";

  tmp.setAttribute("style", "position:absolute;" +
    "top:0;" +
    "left:-1px;" +
    "width:1px;" +
    "height:100vh;");

  document.body.appendChild(tmp);

  vpHeight = document.getElementById("tmp_hght_elm").clientHeight;

  document.body.removeChild(tmp);

  return vpHeight;

}

// ---------------------------------------------------

const ChatSectComp = (props) => {

  const [browsDat, setbrowsDat] = useState({ width: window.innerWidth, height: window.innerHeight });
  const isMobileLayout = browsDat.width <= 900;
  const DomMsgWrapElem = useRef();
  const DomAdsSctElem = useRef();
  const DomChatWrapElem = useRef();

  const interests = props.sharedObj.interests.split(",");
  const setstage = props.sharedObj.setstage;
  const isVidChat = useRef(props.isVidChat);
  let canP2P = props.sharedObj.canP2P;
  //let vidStreamState = props.sharedObj.vidStream; // Main video stream state
  let vidStream = useRef(props.sharedObj.vidStream); // We are using the Ref. As all our functions are subscription based on the sockets so we can not rerender that chunk and useState value don't changes if we don't rerender that specific chunks but the useRef values are uptodate inside those functions chunks
  let [StrangerVidStarted, setStrangerVidStarted] = useState(false);
  const [isVidStreamReady, setisVidStreamReady] = useState();
  //useEffect(() => {vidStream.current = vidStreamState; setisVidStreamReady(vidStreamState ? true : false)}, [vidStreamState]) // On vidStreamState changes, update our vidStream variable
  const [reRender, setreRender] = useState(0);
  const msgsFnsObj = useRef({ getMsg: () => "a", setMsg: (msgT) => true }); // We will pass these object to our textarea component and that will assign the function to these object. So we will be able to acces it. For performance optimization, I use this technique instead of keep re calculating the function on every text change on textarea
  const PrevTypingEvnt = useRef('idle'); // Idle or typing events
  const strangerUser = useRef(false);
  const disabledChat = useRef(true);
  const socket = useRef(false);
  const showMoreOptns = useRef(false);
  const smileyPickerInit = useRef(false)
  const [smileyPckrShow, setsmileyPckrShow] = useState(false);
  const socketHaltPingIntrvlFn = useRef(); // This will be the interval fn which will be keep pinging to the server every specific seconds to check for the halt handshake
  const ReconnectAftrDscnt = useRef(true);
  const ReconnectAftrDscntIntrvlFn = useRef(false);
  const reResearcCnctChat = () => { props.sharedObj.setchatElemKey((oldVal) => oldVal + 1) }
  let SocketInitiated = useRef(false); // We will use this variable to track that if we already initiated the socket
  let p2p = useRef();
  /** These are the status on the .current element
   * [idle: Means at idle and we did not try to connect] 
   * [connected: Connected to the other user using p2p]
   * [fail: Can not able to connect to the other user using p2p]
   */
  let p2pStat = useRef("idle");

  console.log(`ReconnectAftrDscntIntrvlFn fn :`, ReconnectAftrDscntIntrvlFn.current)

  // Our video dom element ref
  let ourVidElem = useRef();

  // Stranger video dom element ref
  let strangerVidElem = useRef();

  let inboundStream = useRef();

  // ------------- Rerender function --------------

  const ReRenderFn = () => {

    setreRender((prev) => {
      return prev + 1;
    })

  }

  // -----------------------------------------------

  const bodMobCls = "mobchtint";


  // ------------- On unmount clean the intervals -------------- //
  useEffect(() => {

    // On unmount
    return () => {
      try { clearInterval(ReconnectAftrDscntIntrvlFn.current) } catch (Err) {/* */ }
    }
  }, [])


  // --------------- Processing mob/non mob things ---------------
  const processingMobNoMob = (IsmMobLayout) => {


    // Add/remove class
    if (IsmMobLayout) { document.body.classList.add(bodMobCls); } else { document.body.classList.remove(bodMobCls); }


    if (!DomMsgWrapElem.current || !DomAdsSctElem.current || !DomChatWrapElem.current) { return }

    // IF is mobile layout then process
    if (IsmMobLayout) {

      const browsVisHeight = browsDat.height //GetVisibleWindHeight();


      // Get the require elements height
      let TpHdrSctHght = document.querySelector("header").offsetHeight;
      let AdsSctHght = DomAdsSctElem.current.offsetHeight;
      let VidSctHght = DomChatWrapElem.current.querySelector(".vid_w").offsetHeight;

      // Calculte the remaining height
      const remngHeight = browsVisHeight - TpHdrSctHght - AdsSctHght - VidSctHght - 10;

      // Set the remaining height to our element
      DomMsgWrapElem.current.style.height = `${remngHeight}px`;

      // Calculate the msg wrap elements inner heights
      const inrTypeBoxHgt = DomMsgWrapElem.current.querySelector(".isctb").offsetHeight;

      const remngInrHeight = remngHeight - inrTypeBoxHgt;

      console.log(`Browser Height: ${browsVisHeight}, TpHdrSctHght: ${TpHdrSctHght}, AdsSctHght: ${AdsSctHght}, VidSctHght: ${VidSctHght}, Remaining Height: ${remngHeight}, inrTypeBoxHgt: ${inrTypeBoxHgt}, Remaining inr height: ${remngInrHeight}`)

      // Set the scrollable area remaining height
      DomMsgWrapElem.current.querySelector(".iscta").style.height = `${remngInrHeight}px`;



    } else {

      // Set default height
      DomMsgWrapElem.current.querySelector(".iscta").style.height = DomMsgWrapElem.current.querySelector(".iscta").getAttribute("data-defheigth");
      DomMsgWrapElem.current.style.height = `inherit`;

    }

  }

  // On the required elements mounted then re run our fn
  useEffect(() => {

    // Process the mob non mob layout things
    processingMobNoMob(isMobileLayout);

  }, [DomMsgWrapElem, DomAdsSctElem, isMobileLayout, DomChatWrapElem])

  // -------------------------------------------------------------

  // Process the mob non mob layout things
  processingMobNoMob(isMobileLayout);



  // ========= On first time element mount then keep track of the browser width ================

  useEffect(() => {
    const handleResize = () => setbrowsDat({ width: window.innerWidth, height: window.innerHeight });
    window.addEventListener('resize', handleResize);

    // On element unmount remove the event listner and remove the mobinit class
    return () => {
      processingMobNoMob(false); // Pass false which will set the non mob layout things
      window.removeEventListener('resize', handleResize)
    };
  }, []);

  // ===========================================================================================


  // ======== If it's video chat then Load our video stream once the element is mounted on dom ========

  useEffect(function () {

    // If it's not video chat then return
    if (!isVidChat.current) { return }

    // Get the media stream
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then((stream) => {


      vidStream.current = stream;
      setVidToElem(ourVidElem.current, stream, true);
      setisVidStreamReady(true)


    }).catch((errT) => {
      alert(`There is error while getting the camera stream. The error is '${errT}'. Terminating the video chat!`);

      // Go back to the default screen
      setstage("default")

    })
  }, [ourVidElem]);

  // ===============================================


   // ************ On p2p-signal event ****************

      // On our main socket, p2p-signal event receieve then set to our p2p object
      const onP2pSignal = (data) => {

        console.log(`P2p on signal data received`, data);

        p2p.current.signal(data.signal);

      }

      // ************************************************


  // ====== Msgs ========

  const [msgsObj, setmsgsObj] = useState({
    prsonName: "stranger",
    infMsg: [],
    msgs: [],
    strangerTyping: false
  });

  const AddMsg = (msgObj, isInfoMsg = false) => {

    // Update the state
    setmsgsObj((prevObj) => {

      let newObjData = { ...prevObj }; // First duplicate the object, we must have to duplicate the object in order to change any property

      // @Depriciated, instead I am using the react <pre> element which preserve line breaks, If have .msg then replace the js line break to html line break
      //if (msgObj.msg) {  msgObj.msg = msgObj.msg.replace(/\n/g, "<br>"); }

      // If info msg then push to info else to msgs
      if (isInfoMsg) {
        newObjData.infMsg.push(msgObj)
      } else {
        newObjData.msgs.push(msgObj)
      }

      return newObjData;
    })

  }


  const UpdateStrangerTypingStat = (stat) => {

    // Update the state
    setmsgsObj((prevObj) => {

      let newObjData = { ...prevObj }; // First duplicate the object, we must have to duplicate the object in order to change any property

      newObjData.strangerTyping = stat === "idle" ? false : true;

      return newObjData;
    })

  }

  // ====================

  // ========== On Message received ===========

  const onMsgRcvd = (data) => {

    // If it is arraybuffer, as if peer connection then we get the array buffer so convert to string
    if (ArrayBuffer.isView(data) && !(data instanceof DataView)) { data = data.toString() }

    try {

      data = typeof data === "object" ? data : JSON.parse(data);

      switch (data.action) {
        case "txt":

          AddMsg({
            me: false,
            msg: data.data
          });


          break;
        case "typingStat":

          // Updating stranger typing stat
          UpdateStrangerTypingStat(data.data);
          break;

        case "switchtotext":

          // Stop the video streamings
          StopVidAndResetVarsFn();

          // Notify using the modal that the stranger stoped the vide and switched to text chat.
          ModalFns.AddModal({
            title: false,
            body: "Stranger shifted the video chat to text chat!",
            closeAble: true,
            showModal: true,
            LargeModal: false
          });

          break;

        case "reqvidchat":

          console.log("Req video chat triggered")

          // Work according to the sub action
          switch (data.data.action) {
            case "req":

              // As stranger rquest for the video chat so show the modal
              ModalFns.AddModal({
                title: "Stranger is Requesting For Video Chat",
                body: <div className="text-center">
                  {/* Accept Button */}
                  <button type="button" onClick={() => {

                    // Get the current camera media stream
                    navigator.mediaDevices.getUserMedia({
                      video: true,
                      audio: true
                    }).then((stream) => {

                      vidStream.current = stream;

                      // Set our stream to dom vid element
                      setVidToElem(ourVidElem.current, stream, true);

                      // Set isVidChat to true
                      ReRenderFn(isVidChat.current = true)

                      // ---- Send the stream to the stranger ---
                      //p2p.current.addStream(stream);
                      vidStream.current.getTracks().forEach(track => p2p.current.addTrack(track, vidStream.current));

                      // ----------------------------------------

                      // Send the accept msg to stranger so the stranger will send the stream
                      sendMSgToSocketOrP2p({
                        action: "reqvidchat",
                        data: { action: "accept" }
                      })

                      // Close the modal
                      ModalFns.CloseCurrentModal();

                    }).catch((err) => { alert(err) })


                  }} className="btn btn-danger me-3">Accept</button>

                  <button type="button" onClick={() => {

                    // Send the reject msg to stranger
                    sendMSgToSocketOrP2p({
                      action: "reqvidchat",
                      data: { action: "reject" }
                    })

                    // Close the modal
                    ModalFns.CloseCurrentModal();

                  }} className="btn btn-success">Reject</button>
                </div>,
                closeAble: false,
                showModal: true,
                LargeModal: false
              });

              break;

            case "reject":

              // The stranger rejected the request so show the msg as alert and set the isvidchat to false
              ModalFns.AddModal({
                title: false,
                body: "Stranger rejected the video chat request!",
                closeAble: true,
                showModal: true,
                LargeModal: false
              });

              ReRenderFn(isVidChat.current = false);

              break;

            case "accept":

              console.log(`Sending the tracks to the stranger!`)

              // Send the stream to the stranger
              //p2p.current.addStream(vidStream.current);
              vidStream.current.getTracks().forEach(track => p2p.current.addTrack(track, vidStream.current));

              break;

            default:
              break;
          }
          break;
          case "p2p-signal":
            console.log("P2p signal triggered on the p2p msg protocol")
            onP2pSignal(data.data)
            break; 
        default:
          break;
      }

    } catch (error) {

    }

  }

  // ==========================================



  // ========== Connect to the socket =========

  const onResponse = (dataT) => {

    switch (dataT.action) {
      case "join": {

        break;
      }
      default:
        break;
    }

    console.log(dataT)
  }

  // ======================= Fn for socket to connect ==================

  const connectWebSocket = () => {
    return new Promise((resolve, reject) => {
      const socket = new WebSocket(Settings.SocketUrl);

      socket.onopen = () => {
        resolve(socket);
      };

      socket.onerror = (error) => {
        reject(error);
      };
    });
  }


  // ===================================================================



  // Connect to the socket on element mount and emit the text join event
  useEffect(() => {

    // Run annoymus async function, as we can not directly make the useffect the async function it's react limitation
    (async () => {

      // If we already initiated the socket then do nothing
      if (SocketInitiated.current) { return }

      // If is vidchat and the isVidStreamReady is not ready then output the message
      if (isVidChat.current && !isVidStreamReady) {
        AddMsg("Waiting for the video stream!", true);
        return; // Terminate the function and this function will auto call once the isVidStreamReady variable is ready as it is usestate
      }


      SocketInitiated.current = true; // We will set this variable here, as on videostream variable changes this useffect fn calls again so to prevent the recalling of this same fn

      let iStrngUsrnm = false;

      // Add the info logs to the message
      //AddMsg("Searching for the stranger", true);


      let iSocket;

      // Add the info logs to the message
      AddMsg("Connecting to the server!", true);

      // Connect to socket
      try {
        iSocket = await connectWebSocket();
      } catch (error) {

        console.log(error);
        // Failed to connect to the server

        try { iSocket.close(); } catch (error) { } // Call close to avoid future calls to socket

        alert("Something is wrong with server please make sure that you are connected to the internet or try again!");

        setstage("default");

      }

      console.log(`Interests: `, interests);


      AddMsg("Searching for stranger ...", true);

      // Join on the server
      try {

        iSocket.send(ReadyActionDatJson("join", {
          Chattype: isVidChat.current ? "video" : "text",
          interests: interests,
        }));

        console.log(`Sending on join data:`, {
          Chattype: isVidChat.current ? "video" : "text",
          interests: interests,
        });

        // ----- Add the interval to keep checking for the halt handshake ----
        socketHaltPingIntrvlFn.current = setInterval(() => {

          try { iSocket.send(ReadyActionDatJson('h-ping')); } catch (error) { }


        }, 10 * 1000);

        // ------------


      } catch (error) {

        // Error while joining
        try { iSocket.close(); } catch (error) { } // Call close to avoid future calls to socket
        alert("Error while joining!");
        setstage("default");

      }


      // ************ On stranger-found event ****************

      const onStrangerFound = (data) => {


        iStrngUsrnm = data.username;

        console.log("Stranger Found", data);

        // Acknowledge on the server to inform that the user is connected
        iSocket.send(ReadyActionDatJson('stranger-connected', { strangerSId: iStrngUsrnm }));

        try {
          // Clear the halt checking ping
          clearInterval(socketHaltPingIntrvlFn.current);
        } catch (err) {/* */ }


        // @todo debug message, remove it after testing
        //AddMsg("Connected to the user using server socket!", true);


        const interest = data.interest;

        // If the interest is not def then show the message that you both shared the same interest
        if (interest && interest !== "def") {
          AddMsg(`Stranger found, you both share the '${interest}' interest. Connecting to stranger!`, true);
        } else {
          AddMsg(`Stranger found. Connecting to stranger!`, true)
        }

        // Set the stranger user name
        strangerUser.current = data.username;

        // Set the chat connected
        ReRenderFn(disabledChat.current = false)



        // ----------- Initiate the p2p connection here if the user can do the p2p connection. As once we connect to the p2p then we will shift from socket communication to p2p communication -------------

        console.log(data.tToken)

        // Initiate the peer connection
        p2p.current = new window.SimplePeer({
          initiator: data.initiator ? true : false, // As for the peer connection the one should be the initiator and second should be the receiver so the server calculate it for us and send this value
          config: { iceServers: data.tToken },
          trickle: true,
          stream: isVidChat.current ? vidStream.current : undefined // If it's video chat then supply the stream
          //objectMode: true // As if we are not enabling it then the event sent data is in the "Uint8Array". Reference #https://github.com/feross/simple-peer/issues/240
        })

        // On peer connection error
        p2p.current.on('error', err => {

          DisconnectSocket();
          // If it were already connected then set as no p2p
          p2pStat.current = "no p2p";

          console.log('error', err);

          /*
         DisconnectSocket();
         ReRenderFn(disabledChat.current = true)
         */

        })

        // Once the local peer connection created then this signal event will be triggered if the intiator is true
        p2p.current.on('signal', data => {
          const offerSignal = data;

          console.log(`P2P connection status`, p2pStat.current);

          /* If the p2p is not connected then send the signal to the socket server so it will send the signal back to the user for initializing the connection with user using p2p protocol.
           else send the signal directly to the p2p user. As the signal is used to initiate the p2p connection or after the connection
           is established then it is also used to send the media i.e audio/video signal.
           As after the p2p connection established we closed the server socket connection to save the resources. So we will then send the signal directly using the p2p protocoll
          */

           // Ready the payload
           const payload = {signal: offerSignal}

          if (p2pStat.current !== "connected") {

            // P2p is not connected to send the data via server socket

            // Send the signal to the user for connect
            try {
              iSocket.send(
                ReadyActionDatJson('p2p-signal',
                  {
                    toSocketId: iStrngUsrnm,
                    data: payload
                  })
              );
            } catch (error) { console.log(`Error while sending the p2p-signal to aws socket user with error:`, error) }

          } else {


            // P2p is connected so send the data directly via p2p
            sendMSgToSocketOrP2p(
              {
                action: "p2p-signal",
                data: payload
              }
            )

          }

        });

        // On p2p successfully connected
        p2p.current.on('connect', () => {

          try {
            // Clear the halt checking ping
            clearInterval(socketHaltPingIntrvlFn.current);
          } catch (err) {/* */ }

          AddMsg("Connected to stranger!", true);

          p2pStat.current = "connected";
          console.log('P2P CONNECTED');

          // Update the server so the server will remove the entries from the db
          socket.current.send(ReadyActionDatJson('p2p-connected'));

          // Disconnect the aws socket, as we no longer required it
          try { socket.current.close() } catch (error) { }

        });


        // On data received from p2p
        p2p.current.on('data', onMsgRcvd);

        // On stream received from p2p then show it on our side
        //p2p.current.on('stream', OnStreamEvnt);

        // On track received from p2p then show it on our side
        p2p.current.on('track', OnTracksEvnt);


        // On p2p connection closed
        p2p.current.on('close', () => {
          DisconnectSocket();
          AddMsg("Stranger is disconnected!", true);
          ReRenderFn(disabledChat.current = true)

        });




        // ----------------------------------------------------------



      }

      // ************************************************


     

      //  ************* On message receive from socket then process it ********************
      iSocket.addEventListener('message', function (event) {

        // Parse the json
        try {
          const imsg = JSON.parse(event.data);

          const { action, data: idata } = imsg;

          // Call based on action
          switch (action) {
            case "stranger-found":
              onStrangerFound(idata);
              break;
            case "p2p-signal":
              onP2pSignal(idata);
              break;
            case "msg":
              onMsgRcvd(idata);
              break;
            default:
              break;
          }

        } catch (error) {
          console.log(`Error catch triggered while parsing the message from socket with error:`, error);
        }

      });
      // ************************************************************

      console.log(`Stranger user`, strangerUser.current)

      // On other user disconnect
      /* @todo new, I am not using this as on aws we use socket to connect the user with each other in p2p protocol
     iSocket.on('usr-disconnect', (data) => { 

       console.log("Other user disconnected", data.user, iStrngUsrnm);

     // If the other user is the connected stranger then disconnect the chat
     if (data.user === iStrngUsrnm) {
       DisconnectSocket();
       AddMsg("Stranger is disconnected!", true);
       ReRenderFn(disabledChat.current = true)
     }


     });
     */


      // On we disconnected
      iSocket.addEventListener('close', function (event) {

        // If we are not connected with the stranger then add error message, as the aws socket connection should close after p2p match maker
        if (disabledChat.current) {
          ReconnectAftrDscnt.current = false;
          DisconnectSocket();
          AddMsg("You are disconnected!", true);
          ReRenderFn(disabledChat.current = true);
        }

      });


      // Set the socket reference and then rerender
      ReRenderFn(socket.current = iSocket);

    })();

  }, [isVidStreamReady]); // As vidStream variable take some time from the browser to set the video stream, so we will update this function


  // -=========================================


  const OnTracksEvnt = (track, stream) => {

    //alert(`On track receive triggered: track type: ${typeof track}, stream type: ${typeof stream}`)

    console.log("On track triggered: " + isVidChat.current)

    // If is not video chat or can not do video chat
    if (!isVidChat.current) { return }


    // Hide the loader
    //document.querySelector("#imgvidloader").style.display = "none";


    //let inboundStream = new MediaStream(track);

    //strangerVidElem.current.srcObject = stream;
    //strangerVidElem.current.play();
    setVidToElem(strangerVidElem.current, stream);


    setStrangerVidStarted(true)

  }

  /* @Depriciated
  const OnStreamEvnt = (stream) => {

    console.log("On stream triggered: " + isVidChat.current)

    // If is not video chat or can not do video chat
    if (!isVidChat.current) {return}

    // Hide the loader
    document.querySelector("#imgvidloader").style.display = "none";

    setVidToElem(strangerVidElem.current, stream);

  }
  */

  const DisconnectSocket = () => {


    try {
      // Clear the halt checking ping
      clearInterval(socketHaltPingIntrvlFn.current);
    } catch (error) { }

    // Server socket connection
    try {
      socket.current.close();
      socket.current = false;
    } catch (error) { /* */ }


    // P2p socket disconnect
    try {
      p2p.current.destroy();
      p2p.current = null;
    } catch (error) {

    }


    // If want's to reconnect and if the interval not already set
    if (ReconnectAftrDscnt.current && typeof ReconnectAftrDscntIntrvlFn.current !== "function") {

      // Add the timeout to reconnect
      ReconnectAftrDscntIntrvlFn.current = setTimeout(() => {

        console.log("Reset chat fn triggered")

        reResearcCnctChat();

      }, 3 * 1000);


      AddMsg("Searching new stranger in 3 seconds", true);

    }




    ReRenderFn(disabledChat.current = true);




    ScrollChatArea(false); // scroll to top


  }


  const ScrollChatArea = (scrolBottom = true) => {
    const el = document.getElementById('actl-msgs-415');
    // id of the chat container ---------- ^^^
    if (el) {
      el.scrollTop = scrolBottom ? el.scrollHeight : 0;
    }
  }



  // ----------- Function to send msg -----------

  const sendMSg = async () => {

    const msgT = msgsFnsObj.current.getMsg();

    if (disabledChat.current) { return alert("You are not connected to the stranger!") }
    if (!msgT) { return alert("Please type the msg first!") };

    // Ready the msg packet
    const msgPacket = {
      action: "txt",
      data: msgT,
    };


    // Set the typing event to idle as when the other user receives the message then it auto set to idle on their side
    PrevTypingEvnt.current = msgPacket.data;

    // Send the data to socket
    const sentStat = await sendMSgToSocketOrP2p(msgPacket);

    // if fail
    if (sentStat === false) {

      // The other user is disconnected
      DisconnectSocket();
      AddMsg("Stranger is disconnected!", true);
      ReRenderFn(disabledChat.current = true)
      return;
    }


    // Here it means it's success
    AddMsg({
      me: true,
      msg: msgT
    });

    msgsFnsObj.current.setMsg("");

    // Hide the emoji picker panel if it is showing
    if (smileyPckrShow) { setsmileyPckrShow(false) }
  }



  // --------------------------------------------


  // ----------- Function to send msg -----------

  /**
   * Send the msg data to the other user. It will auto send via websocker or via p2p according to the connected interface
   * @param {{action: string, data: any}} data 
   * @returns 
   */
  const sendMSgToSocketOrP2p = async (data) => {

    return new Promise(function (resolve, reject) {

      // If p2p is connected then send the message through the p2p else send it through the socket
      if (p2pStat.current === "connected") {

        // Send via p2p connection

        try {

          // Send the data to the peer
          p2p.current.send(JSON.stringify(data));

          return resolve(true);

        } catch (error) {
          return resolve(false);
        }

      } else {

        // Send via socket communication
        try {
          socket.current.send(ReadyActionDatJson('send-msg', { to: strangerUser.current, data: data }));

          return resolve(true);

        } catch (error) {
          return resolve(false);
        }

      }

    });

  }


  // --------------------------------------------



  // --------- Function to send the typing status -----------
  /**
   * Send the typing status to the other user, so he/she will be able to know this user typing status
   */
  const sendTypingStatus = (valT) => {

    if (valT === undefined) { valT = msgsFnsObj.current.getMsg() }

    // If the chat disabled then do nothing
    if (disabledChat.current) { return }

    const msgPacket = {
      action: "typingStat",
      data: "idle"
    };

    let sendEvent = false;

    // If valT length is greater than 0 then send the typing event if not already sent
    if (valT.trim().length > 0 && PrevTypingEvnt.current !== "typing") {
      msgPacket.data = "typing";
      sendEvent = true;
    }

    // If valT length is 0 then send the idle event if not already sent
    if (valT.trim().length === 0 && PrevTypingEvnt.current !== "idle") {
      msgPacket.data = "idle";
      sendEvent = true;
    }


    // If want's to send the msg packet
    if (sendEvent) {
      //console.log('Typing State Event sent: ', msgPacket, `[${valT}]`);
      sendMSgToSocketOrP2p(msgPacket);

      // Set the sent event
      PrevTypingEvnt.current = msgPacket.data;

    }

  }

  // --------------------------------------------------------


  // --------- On stop button clicked --------

  /* When clicked on the stop button then it will check if the chat is 
  */
  const onStopBtn = () => {


    // If chat is disabled then go to homepage
    if (disabledChat.current) {

      // Disable the auto reconnect as we do it our self after specific time
      ReconnectAftrDscnt.current = false;

      // Disconnect the socket
      DisconnectSocket();
      setstage("default");
      return;
    }



    // ________ Count down component to reconnect ____________

    const CountDownToReConnectComp = () => {

      const [CounterTT, setCounterTT] = useState(2);
      const istopCntrIntrvlFn = useRef();

      // ---------------

      useEffect(() => {

        const incrStopCntr = () => {
          // Update the couter
          setCounterTT((oldVal) => {
            let nmb = oldVal;

            nmb--;

            // If number the counter reached to 0 then reconnect and close this modal
            if (nmb <= 0) {
              try { clearInterval(istopCntrIntrvlFn.current) } catch (err) { }

              // Reresearch the stranger
              reResearcCnctChat();

              // Close the current modal
              ModalFns.CloseCurrentModal();

            }

            return nmb

          });
        }

        istopCntrIntrvlFn.current = setInterval(incrStopCntr, 1 * 1000);

      }, [])



      // -----------------------

      return (
        <div key={"countDown"}>
          <p className='text-center'>Reconnecting to new stranger: {CounterTT}</p>
        </div>
      )

    }

    // _______________________________________________________


    // _________ Modal Body Comp ______________

    const ModalBodyComp = () => {

      const [modalState, setmodalState] = useState("init"); // "init", "reconnecting"

      return (
        <div className="text-center">

          {
            modalState === "init" && (
              <>
                <button type="button" onClick={() => {

                  // Disable the auto reconnect as we do it our self after specific time
                  ReconnectAftrDscnt.current = false;

                  // Disconnect the socket
                  DisconnectSocket();

                  // Update the modal state
                  setmodalState("reconnecting")

                }} className="btn btn-danger me-3">Yes</button>

                <button type="button" onClick={() => {

                  // Close the modal
                  ModalFns.CloseCurrentModal();

                }} className="btn btn-success">Cancel</button>
              </>
            )
          }


          {modalState === "reconnecting" && <CountDownToReConnectComp key={"reconnectCountDownComp"} />}


        </div>
      )
    }

    // ________________________________________






    // We are here it means the chat is not disabled and counter is not running so set the counter interval
    ModalFns.AddModal({
      title: "Are you sure you want to end the chat with stranger?",
      body: <ModalBodyComp key={"modalcompi14"} />,
      closeAble: false,
      showModal: true,
      LargeModal: false
    });


    /*

    // If the coutner is running then cancel the counter and do nothing
    if (stopCntrCount !== false) {

      // Clear the interval
      try {clearInterval(stopCntrIntrvlFn.current)} catch(err) {}

      // Set to false
      setstopCntrCount(false);

      return;

    }

    // If chat is disabled then go to homepage
    if (disabledChat.current) {
      // Disconnect the socket
      DisconnectSocket();
      setstage("default");
      return;
    }


    //_____ We are here it means the chat is not disabled and counter is not running so set the counter interval ____
    const incrStopCntr = () => {
      // Update the couter
      setstopCntrCount((oldVal) => {
        let nmb = oldVal === false ? 6 : oldVal;

        nmb--;

        // If number the counter reached to speicific seconds then disconnect the chat and clear the interval
        if (nmb <= 0) {
          try {clearInterval(stopCntrIntrvlFn.current)} catch(err) {}
          DisconnectSocket();
        }

        return nmb

      })
    }

    // Call fn to update imediately and set interval fn as well
    incrStopCntr();

    stopCntrIntrvlFn.current = setInterval(incrStopCntr, 1 * 1000);

     // ___________
    
    */
  }

  // -----------------------------------------


  // --------- On home button clicked --------

  /* When clicked on the home btn
  */
  const onHomeBtn = () => {


    // If chat is disabled then go to homepage
    if (disabledChat.current) {

      // Disable the auto reconnect as we do it our self after specific time
      ReconnectAftrDscnt.current = false;

      // Disconnect the socket
      DisconnectSocket();
      setstage("default");
      return;
    }


    // We are here it means the chat is not disabled and counter is not running so set the counter interval
    ModalFns.AddModal({
      title: "Are you sure you want to go to the homepage? Any connected chat will disconnect",
      body: <div className="text-center">
        <button type="button" onClick={() => {

          // Disable the auto reconnect as we do it our self after specific time
          ReconnectAftrDscnt.current = false;

          DisconnectSocket();
          setstage("default");
          ModalFns.CloseCurrentModal();

        }} className="btn btn-danger me-3">Yes</button>

        <button type="button" onClick={() => {

          // Close the modal
          ModalFns.CloseCurrentModal();

        }} className="btn btn-success">Cancel</button>
      </div>,
      closeAble: false,
      showModal: true,
      LargeModal: false
    });

  }

  // -----------------------------------------


  // ------ Scroll to bottom on the msg array change ------

  // As useeffect called after the dom rendered
  useEffect(() => {

    const scrollBottom = !disabledChat.current; // If chat is disabled then it is true else it is false
    ScrollChatArea(scrollBottom);

  }, [msgsObj])

  // -----------------


  // ------------------ More options related ---------------------

  /**
   * Function to stop the video streaming and stop sending the video to peer
   */
  const StopVidAndResetVarsFn = () => {

    // If it's not vid chat then do nothing
    if (!isVidChat.current) { return }

    // Loop through our stream and remove the tracks from p2p so it will stop sending the streams to the stranger
    vidStream.current.getTracks().forEach(track => p2p.current.removeTrack(track, vidStream.current));

    // Reset the variables
    isVidChat.current = false;
    vidStream.current = false;

    setStrangerVidStarted(false)

  }

  // More options dots button click
  const moreOptnsDotsClck = () => {

    // Get the bottom section height
    const btmSctnHght = document.querySelector(".chat_wrp .msg_m_w .isctb").clientHeight;

    // Set the bottom value of our more section elem
    document.querySelector(".chat_wrp .msg_m_w .more_optns").style.bottom = `${btmSctnHght}px`;

    ReRenderFn(showMoreOptns.current = !showMoreOptns.current) // Show/hide the more options section

  }

  // On Request video chat button
  const reqVidChtClck = () => {

    console.log("triggered")

    // Is isVidChat then do nothing
    if (isVidChat.current) { return }

    // If not connected to stranger then aler
    if (p2pStat.current !== "connected") {
      alert("Not connected to stranger!");
      return;
    }


    // Request the video and update the variable
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true
    }).then((stream) => {

      // Set is vidChat to true which will render related things
      ReRenderFn(isVidChat.current = true)

      setVidToElem(ourVidElem.current, stream, true);

      // Set the stream to our useRef variable without rerender
      vidStream.current = stream;

      // Hid more options menu
      ReRenderFn(showMoreOptns.current = false)

      // Send the request to stranger
      sendMSgToSocketOrP2p({
        action: "reqvidchat",
        data: {
          action: "req"
        }
      })

    }).catch((err) => { alert(err) })


  }


  // On switch to text chat button
  const switchToTxtChtClck = () => {


    // If not isVidChat then do nothing
    if (!isVidChat.current) { return }

    // If not connected to stranger then aler
    if (p2pStat.current !== "connected") {
      alert("Not connected to stranger!");
      return;
    }


    // Set ref variable which will Hide the more options menu on function rerender which will triggered in the StopVidAndResetVarsFn
    showMoreOptns.current = false;

    // Send the request to stranger to stop video and switch to text chat
    sendMSgToSocketOrP2p({
      action: "switchtotext",
      data: {}
    });


    // Stop and reest vid fn
    StopVidAndResetVarsFn();

  }


  // ---------------------------------------------------




  // If strnger is typing then append on array
  let msgArr = [...msgsObj.msgs]; // clone the array
  if (msgsObj.strangerTyping) { msgArr.push({ me: false, msg: "Stranger is typing ..." }) }


  return (

    <>

      <div className='backtohome' onClick={onHomeBtn}>
        <svg viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
      </div>

      <div ref={DomAdsSctElem} className='d-inline-block w-100 ads_sct3255 mx-0'>
        {
          //If it's video chat then show minimal ad else show full ad
          isVidChat.current ? <MinimalAdComp key={"miniadComp1"} /> : <FullAdsComp key={"fulladComp1"} />

        }
      </div>

      <div ref={DomChatWrapElem} className='row mx-0 chat_wrp'>

        <VideoLayoutComp p2p={p2p} vidStream={vidStream} isVidChat={isVidChat} ourVidElem={ourVidElem} strangerVidElem={strangerVidElem} StrangerVidStarted={StrangerVidStarted} key={"videoLayoutComp"} />

        <div className={`${isVidChat.current ? "col-7" : "col-12"} icht_w`}>


          <div ref={DomMsgWrapElem} className="msg_m_w u_bx_stl1" key={"ubxt"}>

            <div className="iscta p-3" id="actl-msgs-415" data-defheigth={"60vh"} style={{ "overflow": "auto" }}>

              <div className="inf_msgs">
                {
                  msgsObj.infMsg.map((msg, indx) => {
                    return (
                      <p key={indx} className="mb-1">{msg}</p>
                    )
                  })
                }
              </div>


              <div className="actl_msgs mt-2">
                {

                  msgArr.map((msgsObj, indx) => {
                    return (
                      <div key={indx} className={`itm mt-2 ${msgsObj.me && "me"}`}>
                        <div className="iinr"> <pre>{msgsObj.msg}</pre> </div>
                      </div>
                    )
                  })
                }
              </div>

            </div>

            {
              // To optimize for performance. We will not render the emoji picker at start. We will only render it if the smileyPickerInit is true. And after that we will just show/hide the emoji picker
              smileyPickerInit.current && (
                <div className={`smiley_picker_w ${!smileyPckrShow && "d-none"}`}>
                  <EmojiPicker key={"smiley_picker"} lazyLoadEmojis={true} onEmojiClick={(emoji, event) => {

                    // Get our msg box dom element
                    const domTxtElem = document.getElementById('msgtxtbox');

                    const msgT = msgsFnsObj.current.getMsg();

                    // Insert the emoji at selected pointer
                    msgsFnsObj.current.setMsg(
                      msgT.substring(0, domTxtElem.selectionStart) +
                      emoji.emoji +
                      msgT.substring(domTxtElem.selectionEnd)
                    );

                  }} />
                </div>
              )
            }



            <div className={`more_optns ${!showMoreOptns.current && "d-none"}`}>
              <div className="iinr">

                {

                  // Only output this element if the chat is active with stranger
                  disabledChat.current === true ? null :
                    isVidChat.current ?
                      // It's video chat so show the switch to text chat button
                      (<div key={"switchtotextcht"} className='iitm' onClick={switchToTxtChtClck}>
                        <div className='iicn'>
                          <img src='/Images/camera.png' alt='Switch to Text Chat' />
                          <p className='iitxt1'>Switch To Text Chat</p>
                        </div>
                      </div>)
                      :
                      // It's not video chat so show the request video chat button
                      (<div key={"reqvidchatbtn"} className='iitm' onClick={reqVidChtClck}>
                        <div className='iicn'>
                          <img src='/Images/camera.png' alt='Request Video Chat' />
                          <p className='iitxt1'>Request Video Chat</p>
                        </div>
                      </div>)

                }

                {

                }




              </div>
            </div>

            <div className="isctb py-2" style={{ "backgroundColor": "#EEEEEE" }}>
              <div className="row mx-0 align-items-center row_245">

                <div className="col-2 clm aa text-center" style={{ "width": "81px", "paddingRight": "0px" }}>

                  <div className="d-inline-block w-100 p-0 iactn_btn_cnt" >
                    <button onClick={onHomeBtn} className="btn home p-0 mb-2"> <div className='ibtn' style={{ backgroundColor: "#04a909" }}>HOME</div> </button>

                    <button onClick={onStopBtn} className="btn p-0"><div className='ibtn'>
                      <span>End</span>
                    </div> </button>
                  </div>
                </div>

                <div className="col-auto clm b" style={{ "width": "calc(100% - 153px)" }}>
                  <InputReactComp msgsFnsObj={msgsFnsObj} sendMSg={sendMSg} sendTypingStatus={sendTypingStatus} key={"msg_bx"} />
                </div>

                <div className="col-2 clm a text-center" style={{ "width": "70px", "paddingLeft": "0px", "paddingRight": "5px" }}>
                  {
                    // Only show these button if the chat is not disabled
                    !disabledChat.current && (
                      <button title='More Options' onClick={moreOptnsDotsClck} className="btn p-0 btn1 mroptns d-block mx-auto mb-3">
                        <svg style={{ width: "35px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 29.96"><defs></defs><path className="cls-1" d="M0,15A15,15,0,1,1,15,30,15,15,0,0,1,0,15Zm92.93,0a15,15,0,1,1,15,15,15,15,0,0,1-15-15ZM46.46,15a15,15,0,1,1,15,15,15,15,0,0,1-15-15Z"></path></svg>
                      </button>
                    )
                  }

                  <button onClick={() => { smileyPickerInit.current = true; setsmileyPckrShow((oldVal) => !oldVal) }} className="btn p-0 btn2 smiley mt-2 d-block mx-auto"> <img src="./Images/smile.png" /></button>

                  <div className="d-inline-block w-100 p-0 iactn_btn_cnt" >
                    <button onClick={sendMSg} className="btn p-0"> <div className='ibtn' style={{ backgroundColor: "#04a909" }}>
                      <svg viewBox="0 0 512 512" style={{ marginLeft: "-4px" }}><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"></path></svg>
                    </div> </button>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>

      </div>

    </>
  )

}


// ==============================================================



// ============ Video Component ===============

const VideoLayoutComp = (props) => {

  const p2p = props.p2p;
  const vidStream = props.vidStream;
  const isVidChat = props.isVidChat;
  const ourVidElem = props.ourVidElem
  const strangerVidElem = props.strangerVidElem;
  const StrangerVidStarted = props.StrangerVidStarted;
  const [vidSwitched, setvidSwitched] = useState(false); // Used the variable for switching between the our and stranger video full box
  const [rerender, setrerender] = useState(1);
  const [micMuted, setmicMuted] = useState(false); // We will use this variable to keep track that if we muted the mic which will stop streaming the audio to peer

  // ____ On vide element clicked then switch the video ________

  const switchVidFn = () => {

    setvidSwitched((oldVal) => {
      return !oldVal // Alter the boolean value
    });

  }

  // __________________________


  // ____ On audi element clicked then mute/unmute audio ________

  const switchAudioFn = () => {


    // Get our streams tracks
    vidStream.current.getTracks().forEach((track) => {

      // If it is not audio then skip
      if (track.kind !== "audio") { return }

      // We are here it means it's audio track

      // If our mic is muted then stream the audio track to the stranger else stop streaming the audio track to stranger
      if (micMuted) {

        // mic already muted, so enabled the audio track
        track.enabled = true;

      } else {

        // mic is not already muted, so disabled the audio track
        track.enabled = false;

      }


      // Alter the micMuted val
      setmicMuted((oldVal) => !oldVal)

    });

  }

  // __________________________


  return (
    <div className={`col-5 vid_w ${!isVidChat.current && "d-none"}`}>

      <div className={`sound_switch ${!StrangerVidStarted && "d-none"}`} onClick={switchAudioFn}>
        <div className='icn_w'>
          {
            micMuted ?
            <svg viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L472.1 344.7c15.2-26 23.9-56.3 23.9-88.7V216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 21.2-5.1 41.1-14.2 58.7L416 300.8V96c0-53-43-96-96-96s-96 43-96 96v54.3L38.8 5.1zM344 430.4c20.4-2.8 39.7-9.1 57.3-18.2l-43.1-33.9C346.1 382 333.3 384 320 384c-70.7 0-128-57.3-128-128v-8.7L144.7 210c-.5 1.9-.7 3.9-.7 6v40c0 89.1 66.2 162.7 152 174.4V464H248c-13.3 0-24 10.7-24 24s10.7 24 24 24h72 72c13.3 0 24-10.7 24-24s-10.7-24-24-24H344V430.4z"/></svg>
              :
              <svg viewBox="0 0 384 512"><path d="M192 0c-53 0-96 43-96 96v160c0 53 43 96 96 96s96-43 96-96V96c0-53-43-96-96-96zM64 216c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 89.1 66.2 162.7 152 174.4V464h-48c-13.3 0-24 10.7-24 24s10.7 24 24 24h144c13.3 0 24-10.7 24-24s-10.7-24-24-24h-48v-33.6c85.8-11.7 152-85.3 152-174.4v-40c0-13.3-10.7-24-24-24s-24 10.7-24 24v40c0 70.7-57.3 128-128 128S64 326.7 64 256v-40z"/></svg>
          }
        </div>
      </div>

      <div onClick={switchVidFn} className={`vid_i a ${vidSwitched && "full"}`}>
        <div className='iinr'>
          <div className='vid_ovrl'>
            <video key={"ourVid"} ref={ourVidElem} playsInline autoPlay muted={true}></video>
          </div>

        </div>

      </div>
      <div onClick={switchVidFn} className={`vid_i b ${!vidSwitched && "full"}`}>

        <div className='iinr'>
          <div className='vid_ovrl'>
            <video key={"strangerVid"} playsInline autoPlay muted ref={strangerVidElem}></video>
          </div>

          {!StrangerVidStarted && <img id='imgvidloader' className='loader' src="/Images/loading.gif" alt="Loading" />}

        </div>

      </div>
    </div>
  )

}

// ==================================


// ==================== Ad components ========================

const FullAdsComp = (props) => {

  return (
    <div className={`ads_sct1 ${props.extraCls}`}>
      <div className="row mx-0">
        <div className="col-6 ps-0 pe-1">
          <div
            className="d-inline-block w-100"
            style={{
              backgroundColor: "#D9D9D9",
              paddingTop: "25%",
              paddingBottom: "25%",
              textAlign: "center"
            }}
          >
            AD 1
          </div>
        </div>
        <div className="col-6 pe-0 ps-1">
          <div
            className="d-inline-block w-100"
            style={{
              backgroundColor: "#D9D9D9",
              paddingTop: "25%",
              paddingBottom: "25%",
              textAlign: "center"
            }}
          >
            AD 2
          </div>
        </div>
      </div>
    </div>
  )

}

const MinimalAdComp = () => {
  return (
    <div className='d-inline-block w-100'>
      <div
        className="d-inline-block w-100"
        style={{
          backgroundColor: "#D9D9D9",
          paddingTop: "7%",
          paddingBottom: "7%",
          textAlign: "center"
        }}
      >
        AD 1
      </div>
    </div>
  )
}

// ============================================================

const Homepage = () => {

  const [isAllowed, setisAllowed] = useState(true);
  const [stage, setstage] = useState("default");
  const [interests, setInterests] = useState("def");
  const [canP2P, setcanP2P] = useState("idle");
  const [vidStream, setvidStream] = useState();
  const [chatElemKey, setchatElemKey] = useState(1);
  const [theme, settheme] = useState("default");

  const sharedObj = {
    setstage,
    interests,
    setInterests,
    canP2P,
    vidStream,
    setvidStream,
    setchatElemKey,
    theme,
    settheme
  }


  // On element mount then ask for terms and condition aggree box
  useEffect(() => {


    ModalFns.AddModal({
      title: "Terms & Condition",
      body: <div className='text-center'>
        <p>
          "I agree to abide by Hawfar's terms of service and our community guidelines.
          I'm 18+ and understand that users are responsible for their own behaviour on Hawfar."</p>

        <button type="button" onClick={() => {

          // Close the modal
          ModalFns.CloseCurrentModal();

        }} className="btn btn-success me-3">Agree</button>

        <button type="button" onClick={() => {

          setisAllowed(false);

          // Close the modal
          ModalFns.CloseCurrentModal();

        }} className="btn btn-danger">Not Agree</button>

      </div>,
      closeAble: false,
      showModal: true,
      LargeModal: false
    });

  }, [])


  // On element mount detect if the user can do the p2p or not
  /*
  useEffect(() => {

    //console.log("Homepage mount useffect function triggered")

    // Annoymus async function, as we can not do the async to the useEffect function so we have to use here
    (async () => {

      const normalNat = await isNormalNat();

      // Set on the state
      setcanP2P(normalNat);

      console.log("Can do p2p communication: ", normalNat);

    })();

    return () => {
      // will run on every unmount.
       console.log("component is unmounting");
      }

  }, [])
*/


  return (
    <>
      <section className="sct1 d-inline-block w-100">
        <div className="row irwa mx-0">



          <div className="col-12 clma">
            <div className="text-center my-3 oflhdn iiscta">
              <h4 className="itxt1 hvr2">Talk to strangers!</h4>
            </div>

            {isAllowed ?
              (<div className="isct1 cstmcntr p-0">
                <div className="iinr">

                  {stage === "default" && <DefaultChatSctn key={"defltChatSect"} sharedObj={sharedObj} />}
                  {stage === "txt_chat" && <ChatSectComp key={`chatTxtElem-${chatElemKey}`} sharedObj={sharedObj} />}
                  {stage === "vid_chat" && <ChatSectComp key={`chatVidElem-${chatElemKey}`} sharedObj={sharedObj} isVidChat={true} />}

                </div>
              </div>)
              :
              // Not allowed
              <h5 className='text-center' style={{ maxWidth: "650px", marginLeft: "auto", marginRight: "auto", color: "#ef3838" }}> You can not chat with strangers as you did not aggree to our terms and Conditions! I order to use our service then please refresh the page and aggree to our terms! </h5>

            }


          </div>
        </div>
      </section>
      <section className="sct2 mt-4">
        <div className="row cstmcntr">
          <div className="col-12">
            <div className="isct1 text-center">
              <h4 className="itxt1 hvr2">
                Hawfar is Nigeria's premier conversation platform
              </h4>
              <p className="itxt2 mx-auto">
                Let's get the conversations going. Chat with Strangers, make
                friends, connect with people, have debates, create relationships and
                friendships, this is what we are all about at Hawfar! You don't need
                an app to use Hawfar on your phone or tablet!
                <br />
                The web site works great on mobile.
              </p>
            </div>
            <div className="isct2 mt-5">
              <div className="row mx-0">
                <div className="col-12 col-md-6 col-lg-3 px-4 mb-4 mb-lg-0">
                  <div className="bxstl1 hvr1 h-100">
                    <div className="iinr">
                      <img className="icn hvr3" src="./Images//Icon_1.png" />
                      <p className="iitxt1 hvr2">Easy To Use</p>
                      <p className="iitxt2">
                        Its really simple, just click text or video to start
                        chatting with new people.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 px-4 mb-4 mb-lg-0">
                  <div className="bxstl1 hvr1 h-100">
                    <div className="iinr">
                      <img className="icn hvr3" src="./Images//Icon_2.png" />
                      <p className="iitxt1 hvr2">Smart Matching</p>
                      <p className="iitxt2">
                        You are matched to random people or to people who have
                        selected a similar interest as you.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 px-4 mb-4 mb-lg-0">
                  <div className="bxstl1 hvr1 h-100">
                    <div className="iinr">
                      <img className="icn hvr3" src="./Images//Icon_3.png" />
                      <p className="iitxt1 hvr2">Safest Platform</p>
                      <p className="iitxt2">
                        To help you stay safe, chats are anonymous unless you tell
                        someone who you are (not recommended!), and you can stop a
                        chat at any time.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 px-4 mb-4 mb-lg-0">
                  <div className="bxstl1 hvr1 h-100">
                    <div className="iinr">
                      <img className="icn hvr3" src="./Images//Icon_4.png" />
                      <p className="iitxt1 hvr2">Clean Chatting</p>
                      <p className="iitxt2">
                        We strongly advise and encourage all users to be nice, by
                        using the site users agree to our terms of service.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="sct1 text-center">
            All Rights Reserved © HawFar ||{" "}
            <a className="hvr1 d-inline-block" href="#">
              Privacy Policy
            </a>{" "}
            ||{" "}
            <a className="hvr1 d-inline-block" href="#">
              Terms &amp; Conditions
            </a>
          </div>
        </footer>
      </section>
    </>

  )
}


export default Homepage;