// As on the whole app we are using only one modal so we can use this file to manipulate the modal

// Store the referenec of setstate function to this function
let AddModalCntntFn;
let GetModalDomElemFn;


/**
 * Get the default modal options
 * @returns {modalDataDoc}
 */
const getModalOptns = () => {
    return {
        title: "Modal",
        body: "Modal Body",
        LargeModal: true,
        showModal: true,
        closeAble: true,
        CloseAllOnClose: false,
        onCloseCallBackFn: undefined,
        id: `${Math.floor(Math.random() * 100)}-${Date.now()}` // will be used to cache for the react performance
    }
}


/**
 * The modal data option
 * @typedef {Object} modalDataDoc
 * @property {string} title - If we want to hide the title then provide boolean value false
 * @property {string | import("react").ReactComponentElement} body - Provide the body html or react element
 * @property {boolean} LargeModal - If want's to show large modal
 * @property {boolean} showModal - If we want to show the moda
 * @property {boolean} closeAble - Supply false If we don't want this modal to be closeable by the cross icon or by clicking elsewhere
 * @property {boolean} CloseAllOnClose - As our modal is like paging, means if we close the current then it will check for previous to show. If we want to close all previous modal when current modal closed then supply true
 * @property {Function} onCloseCallBackFn - If want's to call the specific onCloseCallBackFn
 * @property {string} id - Auto generated id
 */


const ModalFns = {



    /**
  * Function to add the modal
  * @param {modalDataDoc} modalOptions Provide the modal option object, we don't need to provide all values if we leave any value then it will pick the default
  */
    AddModal: function (modalOptions) {

        // Set the state
        AddModalCntntFn(modalOptions);

    },


    /**
     * Close current modal
     */
    CloseCurrentModal: function() {
        const modalDomElem = GetModalDomElemFn();

        modalDomElem.hide();
    },

    /**
     * Function to get the modal options, we can provide our values so it will modify the default values with our values and return.
     * @param {modalDataDoc} optionsT 
     */
    _GetModalOptns: function (optionsT = undefined) {

        let modalOptions = getModalOptns();

        // If we provided the options object then modify the values
        if (typeof optionsT === "object" && optionsT !== null) {

            for (const key in optionsT) {

                if (!Object.prototype.hasOwnProperty.call(optionsT, key)) { continue; }

                modalOptions[key] = optionsT[key];


            }

        }


        return modalOptions;

    },

    /**
     * Function to set the modal content add function
     * @param {Function} setStateFn 
     */
    _SetModalCntnFunc: function (setStateFn) {
        AddModalCntntFn = setStateFn;
    },


    /**
     * Function to set the modal dom elem function
     * @param {Function} setStateFn 
     */
    _SetGetModalElemFn: function (fnc) {
        GetModalDomElemFn = fnc;
    }

}

export default ModalFns;